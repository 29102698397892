import { inject } from 'vue';
import type { LoaderMethods, IngredientEditorMethods } from '~/types';
import { loaderKey, ingredientEditorKey } from '~/fixtures';

// This in fact returns the instance type of the Loader component
export const useLoader = (): LoaderMethods | undefined => {
    return inject(loaderKey)?.value;
};
export const useIngredientEditor = (): IngredientEditorMethods | undefined => {
    return inject(ingredientEditorKey)?.value;
};
